import React, {ComponentProps} from 'react';
import Container from "@/Components/Container";
import Card from "@/Components/Card";
import Button, {ButtonTheme} from "@/Components/Button";
import '../../css/guest.css';

export default function ErrorLayout({title, description, children, statusCode, action, actionText = "Try again"}: ComponentProps<'div'> & {
    title: string,
    description: string,
    statusCode?: number,
    action?: () => void
    actionText?: string
}) {
    return (
        <>
            <Container className="flex flex-col justify-center min-h-screen">

                <Card className="mx-auto max-w-md sm:max-w-lg" role="alert">

                    <h1 className="font-display font-semibold text-2xl mb-4">
                        {statusCode && <span className="text-brand-600">{statusCode} </span>}
                        {title}
                    </h1>
                    <p>{description}</p>

                    {action && <Button onClick={action} theme={ButtonTheme.Secondary} className="mt-6">{actionText}</Button>}

                    {children}

                </Card>

            </Container>
        </>
    );
}
