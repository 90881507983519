import clsx from "clsx";
import {ElementType, HTMLAttributes} from "react";

export function CardTitle({className = '', children, ...props}: HTMLAttributes<HTMLElement>) {
    return (<h1 {...props} className={clsx("font-display font-semibold text-xl sm:text-2xl", className)}>{children}</h1>);
}

export default function Card({as = 'div', className = '', compact = false, padding = true, children}: HTMLAttributes<HTMLElement> & { as?: ElementType, compact?: boolean, padding?: boolean }) {

    const Component = as ?? "div";
    const paddingClassName: string = compact ? "py-5 px-2" : "py-6 sm:py-8 px-7 sm:px-8"

    return (
        <Component className={clsx(
            "rounded-2xl border-b-2 bg-white border border-stone-900/10 ", padding && paddingClassName, className)}>
            {children}
        </Component>
    );
}
