import React, {createContext, PropsWithChildren, useEffect, useState} from 'react';
import Modal from '@/Components/Modal';
import {HubspotFormConfig} from "@/types";
import clsx from "clsx";
import {IconCookie, IconLoader2} from "@tabler/icons-react";
import Button, {ButtonScale, ButtonTheme} from "@/Components/Button";
import telemetry from "@/telemetry";
import {ReportableError} from "@/types/telemetry";
import {consent} from "@/common";

interface ContactDialogState {
    isOpen: boolean;
    config: HubspotFormConfig;
    closeDialog: () => void;
    openDialog: () => void;
}

export const ContactDialogContext = createContext<ContactDialogState>({
    isOpen: false,
    config: { region: "", portalId: "", formId: "" },
    closeDialog: () => {/* null */},
    openDialog: () => {/* null */}
});

export function ContactDialogProvider({config, children}: PropsWithChildren<{ config?: HubspotFormConfig }>) {

    if (config === undefined) {
        // Hubspot is not enabled
        return children;
    }

    const [open, setOpen] = useState(false);

    const state = {
        isOpen: open,
        config: config,
        openDialog: () => setOpen(true),
        closeDialog: () => setOpen(false)
    };

    return (
        <ContactDialogContext.Provider value={state}>
            {children}
            <ContactDialog {...state}/>
        </ContactDialogContext.Provider>
    );
}

export default function ContactDialog(state: ContactDialogState) {

    const [loaded, setLoaded] = useState(false);

    const allowed = consent().functionality;

    useEffect(() => {
        if (allowed && window.hbspt && state.isOpen) {
            try {
                window.hbspt.forms.create({
                    region: state.config.region,
                    portalId: state.config.portalId,
                    formId: state.config.formId,
                    target: '#hubspotFormPopup',
                    onFormReady: () => {
                        setLoaded(true);
                    }
                });
            } catch (e) {
                telemetry.report(e as ReportableError);
                alert("An error occurred");
                close();
            }
        }
    }, [state.isOpen]);

    const close = () => {
        state.closeDialog();
        setTimeout(() => setLoaded(false), 100);
    }

    const updateConsent = () => {
        close();
        consent().openChoices()
    }

    return (
        <>
            <Modal open={state.isOpen} onClose={close} expanded={loaded}>
                {allowed && <>
                    <div id="hubspotFormPopup" className={clsx(!loaded && "hidden")}/>
                    {!loaded && <div className={"flex items-center justify-center gap-2 py-3"}>
                        <IconLoader2 className="animate-spin"/> Loading ...
                    </div>}
                </>}
                {!allowed && <div>
                    <div className="flex items-center py-2 gap-4">
                        <div className="bg-brand-50 text-brand-600 p-3 rounded-full">
                            <IconCookie/>
                        </div>
                        <p>
                            Please accept functionality cookies to open this form. {!consent().loaded && <>You may need to disable your ad blockers.</>}
                        </p>
                    </div>
                    <div className="mt-5 sm:mt-6 flex flex-col gap-2">
                        <Button as="button" onClick={updateConsent} scale={ButtonScale.Large} theme={ButtonTheme.Secondary}>Open cookie preferences</Button>
                    </div>
                </div>}
            </Modal>
        </>
    );
};
