import ErrorLayout from "@/Layouts/ErrorLayout";
import React from "react";

export default function RenderFallback(props: {
    error: Error,
    clearError?: () => void,
    resetErrorBoundary?: () => void
}) {

    return (
        <ErrorLayout
            title="An error occurred"
            description="The application encountered an error from which it was not able to recover. Please try refreshing this page or trying again later."
            action={() => window.location.replace("/")}
            actionText="Return home"
        />
    );
}
