import clsx from "clsx";
import {HTMLAttributes} from "react";

interface ContainerProps extends HTMLAttributes<HTMLElement> {
    expanded?: boolean;
}

export default function Container({className = '', expanded = false, children}: ContainerProps) {

    let width = expanded ? "max-w-5xl" : "max-w-2xl";

    return (
        <div className={clsx("mx-auto px-2", width, className)}>
            {children}
        </div>
    );
}
