import {createConfig, http} from "wagmi";
import {mainnet, polygon, polygonAmoy, polygonMumbai, sepolia} from "viem/chains";
import {walletConnect} from "wagmi/connectors";
import {Chains} from "@/types";
import {Chain, createClient} from "viem";

export const availableChains: Chains = {
    "ethereum": mainnet,
    "polygon": polygon,
    "mumbai": polygonMumbai, // deprecated: kept for compatibility
    "amoy": polygonAmoy,
    "sepolia": sepolia
}

export function getChain(chainAppId: string): Chain {
    return availableChains[chainAppId];
}

export const config = (defaultNetwork: string, enabledNetworks: string[]) => {

    const chains: Readonly<[Chain, ...Chain[]]> = [
        getChain(defaultNetwork),
        ...enabledNetworks.filter((key) => key !== defaultNetwork).map(getChain)
    ];

    return createConfig({
        chains: chains,
        client({ chain }) {
            return createClient({ chain, transport: http() })
        },
        connectors: [
            walletConnect({
                projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
                metadata: {
                    name: import.meta.env.VITE_APP_NAME,
                    description: "AlmaMint issues unique and verifiable tokens for your education credentials, course certificates, and digital diplomas.",
                    url: import.meta.env.VITE_APP_URL,
                    icons: [
                        new URL("/walletconnect.png", import.meta.env.VITE_APP_URL).toString()
                    ],
                },
                qrModalOptions: {
                    privacyPolicyUrl: route('legal.privacy'),
                    termsOfServiceUrl: route('terms'),
                    themeVariables: {
                        "--wcm-background-color": "#e84f27",
                        "--wcm-accent-color": "#e84f27",
                        "--wcm-container-border-radius": "1rem",
                        "--wcm-background-border-radius": "1rem"
                    },
                },
            })
        ]
    });
};
