import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import {router} from "@inertiajs/react";
import {ReportableError, TelemetryConfig} from "@/types/telemetry";
import React from "react";
import {PageProps} from "@/types";
import {consent} from "@/common";

const enabled = () => Bugsnag.isStarted();

const start = (config: TelemetryConfig) => {

    if (!config.apiKey) {
        return;
    }

    Bugsnag.start({
        apiKey: config.apiKey,
        releaseStage: config.releaseStage,
        appVersion: config.appVersion,

        plugins: [new BugsnagPluginReact()],

        generateAnonymousId: false,

        onError: (event) => {
            if (consent().measurement === false) {
                return false;
            }
        },
    });

    router.on('navigate', (event) => {
        const pageProps = event.detail.page.props as Partial<PageProps>;
        Bugsnag.setUser(pageProps.auth?.user ? String(pageProps.auth?.user.id) : undefined);
    });

}

const createErrorBoundary = (react?: typeof React) => {
    return enabled() ? Bugsnag.getPlugin('react')?.createErrorBoundary(react) : undefined;
}

const report = (e: ReportableError) => {
    if (enabled()) {
        Bugsnag.notify(e);
    }
}

export default {
    enabled, start, report, createErrorBoundary
}
