import {ComponentProps, Fragment} from 'react'
import {Dialog as HeadlessDialog, Transition} from '@headlessui/react'
import Button, {ButtonTheme} from "@/Components/Button";

export default function Modal({children, open, onClose = () => {}, afterClose = () => {}, disableClose = false, expanded = false}: ComponentProps<'div'> & {
    open: boolean,
    onClose?: { (): void },
    afterClose?: { (): void },
    disableClose?: boolean,
    expanded?: boolean
}) {

    const widthClass = expanded ? 'sm:max-w-2xl' : 'sm:max-w-sm';

    return (
        <Transition.Root show={open} as={Fragment} afterLeave={afterClose}>
            <HeadlessDialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-stone-800/90 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">

                    {!disableClose && <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="flex justify-end w-full absolute sm:fixed transition-opacity p-4 z-50">
                            <Button className="!bg-stone-600 !text-stone-50/90" onClick={onClose} theme={ButtonTheme.Secondary}>Close</Button>
                        </div>
                    </Transition.Child>}


                    <div className="flex min-h-full items-end justify-center sm:items-center pt-16 sm:pb-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-200"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-100"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <HeadlessDialog.Panel className={`relative transform overflow-hidden rounded-t-2xl sm:rounded-2xl w-full bg-white px-6 py-5 transition-transform ${widthClass}`}>
                                {children}
                            </HeadlessDialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </HeadlessDialog>
        </Transition.Root>
    )
}
