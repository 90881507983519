import './bootstrap';
import '../css/app.css';

import {config} from './wagmi';
import {createRoot} from 'react-dom/client';
import {createInertiaApp} from '@inertiajs/react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import React from 'react';
import {WagmiProvider} from "wagmi";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import Bugsnag from '@bugsnag/js'
import telemetry from "@/telemetry";
import {ErrorBoundary} from "react-error-boundary";
import RenderFallback from "@/Components/RenderFallback";
import {ContactDialogProvider} from "@/Globals/ContactDialog";
import {PageProps} from "@/types";

const appName: string = import.meta.env.VITE_APP_NAME || 'AlmaMint';

const queryClient = new QueryClient();

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({el, App, props}) {

        // @ts-expect-error FIXME
        const initialPageProps = props.initialPage.props as PageProps;

        telemetry.start({
            apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
            releaseStage: import.meta.env.VITE_APP_ENV,
            appVersion: initialPageProps.version
        });

        const AppErrorBoundary = telemetry.createErrorBoundary(React) ?? ErrorBoundary;

        createRoot(el).render(
            <React.StrictMode>
                <AppErrorBoundary FallbackComponent={RenderFallback}>
                    <WagmiProvider config={config(initialPageProps.networks.default, initialPageProps.networks.available)}>
                        <QueryClientProvider client={queryClient}>
                            <ContactDialogProvider config={initialPageProps.hsFormConfig}>
                                <App {...props}/>
                            </ContactDialogProvider>
                        </QueryClientProvider>
                    </WagmiProvider>
                </AppErrorBoundary>
            </React.StrictMode>
        );
    },
    progress: {
        color: '#e84f27', // TODO pull value from tailwind config if possible
    },
}).catch((e) => {
    if (Bugsnag.isStarted()) {
        Bugsnag.notify(e)
    }
});
