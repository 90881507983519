import {PageProps} from "@/types";
import telemetry from "@/telemetry";
import {ReportableError} from "@/types/telemetry";

export function parseDate(date: string): Date {
    return new Date(date);
}

export function formatDate(date: Date): string {
    return date.toLocaleString('en', {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
        timeZoneName: "longGeneric",
        timeZone: 'UTC'
    })
}

export function date(date: string): string {
    return formatDate(parseDate(date));
}

export function formatAccount(account: string): string {
    return `${account.slice(0, 8)}...${account.slice(42 - 8, 42)}`;
}

export function username(props: PageProps): string {
    return props.auth.user.name
        ?? (props.auth.guard.name === 'email'
            ? props.auth.identifier.split('@')[0]
            : (props.auth.guard.name === 'wallet')
                ? formatAccount(props.auth.identifier)
                : props.auth.identifier);
}

export function rescue<T>(callable: () => T, fallback: T, report: boolean = false) {
    try {
        return callable();
    } catch (e) {
        if (report) {
            telemetry.report(e as ReportableError);
        }
        return fallback;
    }
}

export function consent() {
    return {
        loaded: rescue(() => window.consentProviderLoaded, false),
        measurement: rescue(() => window.consentProvider?.measurement(), false),
        functionality: rescue(() => window.consentProvider?.functionality(), false),
        openChoices: () => {
            document.dispatchEvent(new Event("updatePrivacyChoices"));
        }
    }
}
