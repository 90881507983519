import React from "react";
import {Link} from "@inertiajs/react";
import clsx, {ClassValue} from "clsx";
import {Icon, IconArrowRight} from "@tabler/icons-react";

export enum ButtonTheme {
    Primary,
    Secondary,
    Transparent
}

export enum ButtonScale {
    Normal,
    Large,
    Small
}

type ButtonProps =
    (React.ComponentPropsWithoutRef<typeof Link> | React.ComponentPropsWithoutRef<'button'> & { href?: undefined })
    & {
    theme?: ButtonTheme
    scale?: ButtonScale
    filled?: boolean
    disabled?: boolean
    processing?: boolean
    icon?: Icon,
    iconClassName?: string,
    animatedArrowRight?: boolean
}

export default function Button(
    {
        children,
        theme = ButtonTheme.Primary,
        scale = ButtonScale.Normal,
        filled = false,
        processing = false,
        className = '',
        animatedArrowRight = false,
        iconClassName = '',
        ...props
    }: ButtonProps) {

    let color: ClassValue = "";
    let size: ClassValue = "px-4 py-1.5 text-sm"; // ButtonScale.Normal

    if (theme == ButtonTheme.Primary) {
        color = "text-stone-800 bg-stone-500/20 hover:bg-brand-600 hover:text-brand-50 focus-visible:outline-brand-800"

    } else if (theme == ButtonTheme.Secondary) {
        color = "text-stone-800 bg-stone-500/20 hover:bg-stone-800/25 focus-visible:outline-brand-800"

    } else if (theme == ButtonTheme.Transparent) {
        color = "text-stone-700 hover:bg-stone-200/50 hover:text-stone-900 focus-visible:outline-brand-800"
    }

    if (scale == ButtonScale.Large) {
        size = "px-5 py-2.5";
    }

    if (scale == ButtonScale.Small) {
        size = "px-3 py-1 text-sm";
    }

    if (processing) {
        props.disabled = true;
        className = clsx([className, "animate-pulse", theme == ButtonTheme.Primary && "!bg-brand-700 !text-brand-50"]);
    }

    className = clsx(
        "group",
        "inline-flex rounded-2xl items-center justify-center gap-x-1.5 focus:shadow-outline font-medium transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 active:scale-95",
        "disabled:opacity-75 disabled:active:transform-none",
        size, color, className
    )

    if (animatedArrowRight) {
        props.icon = IconArrowRight;
        iconClassName = clsx(iconClassName, "transition-transform transform group-hover:translate-x-0.5 duration-75");
    }

    const inner = (
        <>{children} {props.icon && <props.icon size={20} className={iconClassName}/>}</>
    )

    if (typeof props.href === 'undefined') {
        return (<button className={className} {...props}>{inner}</button>);
    }

    return (
        <Link className={className} {...props}>{inner}</Link>
    );
}
